import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderMain from "./components/HeaderMain";
import FooterMain from "./components/FooterMain";
import HeaderTwo from "./components/HeaderTwo";
import HighLightsHeader from "./components/HighLightsHeader";
import ContactPopUp from "./components/ContactPopUp";
import "../src/assets/css/customstyles.css";
import RoutesComponent from "./routescomponent/RoutesComponent";
import scrolltotop from "../src/assets/images/down-arrow.webp";
import ShortCuts from "./components/ShortCuts";

function App() {
  const [showFooter, setShowFooter] = useState(false);
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  const [vChangeWidth, setVChangeWidth] = useState(false);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  useEffect(() => {
    if (window.innerWidth <= 1220 && window.innerWidth <= 767) {
      setVChangeWidth(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!showContactPopup) {
    }
    setTimeout(() => {
      setShowHeaderFooter(true);
    }, 10);
  }, [showContactPopup]);
  const openContactPopup = () => {
    setShowContactPopup(true);
  };

  const closeContactPopup = () => {
    setShowContactPopup(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  
  return (
    <>
      {!showContactPopup && (
        <>
          {showHeaderFooter &&
            ((location.pathname === "/kals-group" || location.pathname === "/delmonte-foods-can-filling-packaging" || location.pathname === "/can-glass-filling-packaging-vvs-breweries")  ? (
              <HighLightsHeader />
            ) : vChangeWidth ? (
              <HeaderTwo
                showContactPopup={showContactPopup}
                closeContactPopup={closeContactPopup}
                openContactPopup={openContactPopup}
              />
            ) : (
              <HeaderMain
                showContactPopup={showContactPopup}
                closeContactPopup={closeContactPopup}
                openContactPopup={openContactPopup}
              />
            ))}
          <RoutesComponent
            showHeaderFooter={showHeaderFooter}
            setShowHeaderFooter={setShowHeaderFooter}
            setShowFooter={setShowFooter}
            showContactPopup={showContactPopup}
            showFooter={showFooter}
          />
          {scrolled && (
            <>
              <ShortCuts setShowContactPopup={setShowContactPopup} />
              <div onClick={scrollToTop} className="scroll-to-top">
                <img src={scrolltotop} alt="scroll to top" />
              </div>
            </>
          )}
          {showHeaderFooter && showFooter && <FooterMain />}
        </>
      )}
      {showContactPopup && (
        <ContactPopUp
          closeContactPopup={closeContactPopup}
          showContactPopup={showContactPopup}
        />
      )}
    </>
  );
}

export default App;

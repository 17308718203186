import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import NewLoader from "../components/NewLoader";
import ErrorBoundary from "../components/ErrorBoundary";

// Grouping related components to optimize code splitting
const HeroSection = lazy(() => import("../components/HeroSection"));
const ContactUs = lazy(() => import("../components/ContactUs"));
const AboutUs = lazy(() => import("../components/AboutUs"));
const TurnKey = lazy(() => import("../components/TurnKey"));
const TermsAndConditions = lazy(() => import("../components/TermsAndConditions"));
const RapidResale = lazy(() => import("../components/RapidResale"));
const Services = lazy(() => import("../components/Services"));
const BeerInGlass = lazy(() => import("../components/beeringlass/BeerInGlass"));
const CarbonatedSoftDrinkInCan = lazy(() => import("../components/carbonatedsoftdrinkincan/CarbonatedSoftDrinkInCan"));
const SolutionMaps = lazy(() => import("../components/beerincan/SolutionMaps"));
const BeerInKeg = lazy(() => import("../components/beerinkeg/BeerInKeg"));
const ProcessSystemBeer = lazy(() => import("../components/processsystembeer/ProcessSystemBeer"));
const ProcessSystemCsd = lazy(() => import("../components/processsystemcsd/ProcessSystemCsd"));
const RapidHighLights = lazy(() => import("../components/RapidHighLights"));
const RapidHighlightTwo = lazy(() => import("../components/RapidHighlightTwo"));
const RapidHighlightThree = lazy(() => import("../components/RapidHighLightThree"));
const CaseStudy = lazy(() => import("../components/casestudy/Casestudy"));
const ThankYou = lazy(() => import("../components/ThankYou"));
const AllExhibitions = lazy(() => import("../components/AllExhibitions"));
const FourZeroFour = lazy(() => import("../components/errorpage/FourZeroFour"));

const routeConfigs = [
  { path: "*", component: FourZeroFour },
  { path: "/", component: HeroSection },
  { path: "/solutions/beer-in-can", component: SolutionMaps },
  { path: "/contact-us", component: ContactUs },
  { path: "/about-us", component: AboutUs },
  { path: "/turnkey", component: TurnKey },
  { path: "/terms-and-condition", component: TermsAndConditions },
  { path: "/resale", component: RapidResale },
  { path: "/services", component: Services },
  { path: "/solutions/beer-in-glass-botttles", component: BeerInGlass },
  { path: "/solutions/carbonated-soft-drink-in-can", component: CarbonatedSoftDrinkInCan },
  { path: "/solutions/beer-in-keg", component: BeerInKeg },
  { path: "/solutions/process-system-beer", component: ProcessSystemBeer },
  { path: "/solutions/process-system-csd", component: ProcessSystemCsd },
  { path: "/kals-group", component: RapidHighLights },
  { path: "/delmonte-foods-can-filling-packaging", component: RapidHighlightTwo },
  { path: "/can-glass-filling-packaging-vvs-breweries", component: RapidHighlightThree },
  { path: "/highlights", component: CaseStudy },
  { path: "/thank-you", component: ThankYou },
  { path: "/exhibitions", component: AllExhibitions },
];

const RoutesComponent = ({
  showHeaderFooter,
  setShowHeaderFooter,
  setShowFooter,
  showContactPopup,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setShowHeaderFooter(!loading);
    setShowFooter(!loading);
  }, [loading, setShowHeaderFooter, setShowFooter]);

  useEffect(() => {
    if (showContactPopup) {
      setShowHeaderFooter(false);
      setShowFooter(false);
    } else if (!loading) {
      setShowHeaderFooter(true);
      setShowFooter(true);
    }
  }, [showContactPopup, loading, setShowHeaderFooter, setShowFooter]);

  return (
    <Suspense fallback={<NewLoader />}>
      <ErrorBoundary>
        <Routes>
          {routeConfigs.map(({ path, component: Component }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Component
                  showHeaderFooter={showHeaderFooter}
                  setShowHeaderFooter={setShowHeaderFooter}
                  setShowFooter={setShowFooter}
                  setLoading={setLoading}
                  loading={loading}
                />
              }
            />
          ))}
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
};

export default RoutesComponent;
